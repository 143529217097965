import { makeAutoObservable } from "mobx";


export default class PlayerDrawerStore {
  open: boolean = true;
  drawerWidth: number = 240;

  constructor() {
    makeAutoObservable(this);
  }

  setDrawerState = (state: boolean) => {
    this.open = state;
  }
}
import agent from "api/agent";
import { CreateGameBeatRequest, GameBeat, UpdateGameBeatRequest } from "api/gamebeat";
import { makeAutoObservable } from "mobx";

export default class GameBeatStore {
  gameBeatRegistry = new Map<string, GameBeat>();
  selectedGameBeat: GameBeat | undefined = undefined;
  loadingGameBeat = new Set<string>();
  loadingCUD = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadGameBeats = async (groupGamePlayId: string) => {
    this.addLoading(groupGamePlayId);
    try {
      const gameBeats = await agent.GameBeats.listByGroupGamePlay(groupGamePlayId);
      this.setGameBeats(gameBeats);
    } catch (errors) {
      throw errors;
    } finally {
      this.deleteLoading(groupGamePlayId);
    }
  }

  createGameBeat = async (groupId: string, createGameBeatRequest: CreateGameBeatRequest) => {
    this.setLoadingCUD(true);
    try {
      await agent.GameBeats.create(groupId, createGameBeatRequest);
      this.loadGameBeats(createGameBeatRequest.groupGamePlayId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  editGameBeat = async (updateGameBeatRequest: UpdateGameBeatRequest) => {
    this.setLoadingCUD(true);
    try {
      await agent.GameBeats.update(updateGameBeatRequest);
      const gameBeat = await agent.GameBeats.details(updateGameBeatRequest.gameBeatId);
      this.setGameBeat(gameBeat);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  deleteGameBeat = async (gameBeatId: string) => {
    this.setLoadingCUD(true);
    try {
      await agent.GameBeats.delete(gameBeatId);
      this.gameBeatRegistry.delete(gameBeatId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  get gamePlayHasGameBeats() {
    return (groupGamePlayId: string) => this.gameBeatRegistry.has(groupGamePlayId);
  }

  get gameBeatsOrderedByCreatedAt() {
    return Array.from(this.gameBeatRegistry.values()).sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
  }

  private setGameBeats = (gameBeats: GameBeat[]) => {
    gameBeats.forEach(gameBeat => {
      this.setGameBeat(gameBeat);
    })
  }

  private setGameBeat = (gameBeat: GameBeat) => {
    this.gameBeatRegistry.set(gameBeat.gameBeatId, gameBeat);
  }

  private addLoading = (groupGamePlayId: string) => {
    this.loadingGameBeat.add(groupGamePlayId);
  }

  private deleteLoading = (groupGamePlayId: string) => {
    this.loadingGameBeat.delete(groupGamePlayId);
  }

  private setLoadingCUD = (state: boolean) => {
    this.loadingCUD = state;
  }
}
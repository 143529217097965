import agent from "api/agent";
import { makeAutoObservable } from "mobx";
import { DiscoverGroup, DiscoverGroupsRequest } from "../api/group";
import GroupStore from "./groupStore";

export default class DiscoverStore {
  mostPlayersGroupRegistry = new Map<string, DiscoverGroup>();
  mostGameBeatsGroupRegistry = new Map<string, DiscoverGroup>();
  mostGamePlaysGroupRegistry = new Map<string, DiscoverGroup>();
  trendingGroupRegistry = new Map<string, DiscoverGroup>();
  listRegistry = new Map<string, DiscoverGroup>();
  listLength = 0;
  hasMore = false;
  groupStore: GroupStore;
  loadingMostPlayers = false;
  loadingMostGameBeats = false;
  loadingMostGamePlays = false;
  loadingTrending = false;
  loadingList = false;
  loadingJoinGroupId : string | undefined;

  constructor(groupStore: GroupStore) {
    makeAutoObservable(this);
    this.groupStore = groupStore;
  }

  get mostPlayersGroups() {
    return Array.from(this.mostPlayersGroupRegistry.values());
  }

  get mostGameBeatsGroups() {
    return Array.from(this.mostGameBeatsGroupRegistry.values());
  }

  get mostGamePlaysGroups() {
    return Array.from(this.mostGamePlaysGroupRegistry.values());
  }

  get trendingGroups() {
    return Array.from(this.trendingGroupRegistry.values());
  }

  get listGroups() {
    return Array.from(this.listRegistry.values());
  }
  
  loadMostPlayersGroups = async (discoverGroupsRequest: DiscoverGroupsRequest) => {
    this.setLoadingMostPlayers(true);
    try {
      const response = await agent.Groups.discover(discoverGroupsRequest);
      this.setMostPlayerGroups(response.groups);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingMostPlayers(false);
    }
  }

  loadMostGameBeatsGroups = async (discoverGroupsRequest: DiscoverGroupsRequest) => {
    this.setloadingTopGameBeats(true);
    try {
      const response = await agent.Groups.discover(discoverGroupsRequest);
      this.setMostGameBeatsGroups(response.groups);
    } catch (errors) {
      throw errors;
    } finally {
      this.setloadingTopGameBeats(false);
    }
  }

  loadMostGamePlaysGroups = async (discoverGroupsRequest: DiscoverGroupsRequest) => {
    this.setloadingTopGamePlays(true);
    try {
      const response = await agent.Groups.discover(discoverGroupsRequest);
      this.setMostGamePlaysGroups(response.groups);
    } catch (errors) {
      throw errors;
    } finally {
      this.setloadingTopGamePlays(false);
    }
  }

  loadTrendingGroups = async (discoverGroupsRequest: DiscoverGroupsRequest) => {
    this.setloadingTrending(true);
    try {
      const response = await agent.Groups.discover(discoverGroupsRequest);
      this.setTrendingGroups(response.groups);
    } catch (errors) {
      throw errors;
    } finally {
      this.setloadingTrending(false);
    }
  }

  loadListGroups = async (discoverGroupsRequest: DiscoverGroupsRequest) => {
    this.setLoadingList(true);
    try {
      const response = await agent.Groups.discover(discoverGroupsRequest);
      this.listRegistry.clear();
      this.setListGroups(response.groups);
      this.setListLength(response.length);
      this.setHasMore(response.hasMore);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingList(false);
    }
  }

  loadMoreListGroups = async (discoverGroupsRequest: DiscoverGroupsRequest) => {
    try {
      const response = await agent.Groups.discover(discoverGroupsRequest);
      this.setListGroups(response.groups);
      this.setListLength(response.length);
      this.setHasMore(response.hasMore);
    } catch (errors) {
      throw errors;
    } 
  }

  joinGroup = async (groupId: string) => {
    this.setLoadingJoinGroupId(groupId);
    try {
      await agent.Groups.join(groupId);
      this.groupStore.loadGroups();
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingJoinGroupId(undefined);
    }
  }

  private setMostPlayerGroups = (groups: DiscoverGroup[]) => {
    groups.forEach(group => {
      this.mostPlayersGroupRegistry.set(group.groupId, group);
    });
  }

  private setLoadingMostPlayers = (state: boolean) => {
    this.loadingMostPlayers = state;
  }

  private setMostGameBeatsGroups = (groups: DiscoverGroup[]) => {
    groups.forEach(group => {
      this.mostGameBeatsGroupRegistry.set(group.groupId, group);
    });
  }

  private setloadingTopGameBeats = (state: boolean) => {
    this.loadingMostGameBeats = state;
  }

  private setMostGamePlaysGroups = (groups: DiscoverGroup[]) => {
    groups.forEach(group => {
      this.mostGamePlaysGroupRegistry.set(group.groupId, group);
    });
  }

  private setloadingTopGamePlays = (state: boolean) => {
    this.loadingMostGamePlays = state;
  }

  private setTrendingGroups = (groups: DiscoverGroup[]) => {
    groups.forEach(group => {
      this.trendingGroupRegistry.set(group.groupId, group);
    });
  }

  private setListGroups = (groups: DiscoverGroup[]) => {
    groups.forEach(group => {
      this.listRegistry.set(group.groupId, group);
    });
  }

  private setloadingTrending = (state: boolean) => {
    this.loadingTrending = state;
  }

  private setLoadingList = (state: boolean) => {
    this.loadingList = state;
  }

  private setLoadingJoinGroupId = (groupId: string | undefined) => {
    this.loadingJoinGroupId = groupId;
  }

  private setListLength(length: number) {
    this.listLength = length;
  }

  private setHasMore(hasMore: boolean) {
    this.hasMore = hasMore;
  }
}
import { CircularProgress } from '@mui/material';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";

const AuthLayout = lazy(() => import('layouts/Auth/AuthLayout'));
const DashboardLayout = lazy(() => import('layouts/Dashboard/DashboardLayout'));
const Discover = lazy(() => import('views/Discover'));
const DiscoverList = lazy(() => import('views/DiscoverList'));
const GroupDetails = lazy(() => import('views/GroupDetails'));
const GroupInvite = lazy(() => import('views/GroupInvite'));
const LeaderBoards = lazy(() => import('views/LeaderBoards'));
const NotFound = lazy(() => import('views/NotFound'));
const Notifications = lazy(() => import('views/Notifications'));
const Player = lazy(() => import('views/Player'));
const TestErrors = lazy(() => import('views/TestErrors'));
const UserSettings = lazy(() => import('views/UserSettings'));
const ChangeEmail = lazy(() => import('views/auth/ChangeEmail'));
const ConfirmEmail = lazy(() => import('views/auth/ConfirmEmail'));
const ForgotPassword = lazy(() => import('views/auth/ForgotPassword'));
const Login = lazy(() => import('views/auth/Login'));
const Register = lazy(() => import('views/auth/Register'));
const ResetPassword = lazy(() => import('views/auth/ResetPassword'));
const Unsubscribe = lazy(() => import('views/auth/Unsubscribe'));

/**
 * The main component of the application.
 * Renders the routes and layouts for different pages.
 */
export default function App() {
  return (
    <Suspense fallback={<CircularProgress color='inherit' />}>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/confirmEmail" element={<ConfirmEmail />} />
            <Route path="/changeEmail" element={<ChangeEmail />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
          </Route>
          <Route element={<DashboardLayout />} >
            {/* <Route path="/app" element={<YourFeed />} /> */}
            <Route path="/" element={<Navigate to='/discover' />} />
            <Route path="/player/:playerId" element={<Player />} />
            <Route path="/settings" element={<UserSettings />} />
            <Route path="/discover" element={<Discover />} />
            <Route path="/discover/:orderBy" element={<DiscoverList />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/leaderboards" element={<LeaderBoards />} />
            <Route path="/group/name/:name" element={<GroupDetails />} />
            <Route path="/group/:groupId" element={<GroupDetails />} />
            <Route path="/group/:groupId/join/:groupInviteId" element={<GroupInvite />} />
            <Route path="/errors" element={<TestErrors />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate replace to="/not-found" />} />
          </Route>
        </Routes>
    </Suspense>
  );
}
import { CreateGroupGamePlayRequest, CreateGroupRequest, DiscoverGroupResponse, DiscoverGroupsRequest, Group, GroupInvite, KickPlayer, UpdateGroupRequest } from 'api/group';
import { ApproveGroupRequest, GroupRequest } from 'api/groupRequest';
import { Notification } from "api/notification";
import { Player, PlayerDetails } from 'api/player';
import { ConfirmEmailValues, SendChangeEmailValues, UpdateEmailFormValues, UpdateNotificationsFormValues, UpdatePasswordFormValues, UpdateUserNameFormValues, User, UserForgotPasswordFormValues, UserResetPasswordFormValues, UserSignInFormValues } from 'api/user';
import { CreateVote, Vote } from 'api/vote';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { store } from 'stores/store';
import { Game } from './game';
import { CreateGameBeatRequest, GameBeat, UpdateGameBeatRequest } from './gamebeat';
import { GroupGamePlay, StartGroupGamePlayRequest, UpdateGroupGamePlayRequest } from './groupGamePlay';

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(config => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
})

axios.interceptors.response.use(async response => {
  // TODO: Remove sleep
  // await sleep(1000);

  return response;
}, (error: AxiosError) => {

  const { data, status, config } = error.response as AxiosResponse;

  switch (status) {
    case 400:
      if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
        window.location.href = '/not-found';
      }
      // Bad Request
      //enqueueSnackbar('Bad Request', { variant: 'error' })
      console.log(data);
      if (data.errors) {
        //console.log("errors found");
        //throw data.errors;
        // const modelStateErrors = [];
        // for (const key in data.errors) {
        //   if (data.errors[key]) {
        //     modelStateErrors.push(data.errors[key]);
        //   }
        // }
        // throw modelStateErrors.flat();

        for (const key in data.errors) {
          data.errors[key] = data.errors[key].join();
        }
        throw data.errors;
      } else {
        enqueueSnackbar(data, { variant: 'error' })
      }
      break;
    case 401:
      // UnAuthorized
      enqueueSnackbar('Unauthorized', { variant: 'error' })
      break;
    case 403:
      // Forbidden
      enqueueSnackbar('Forbidden', { variant: 'error' })
      break;
    case 404:
      // Not Found
      //enqueueSnackbar('Not Found', { variant: 'error' })
      window.location.href = '/not-found';
      break;
    case 500:
      // Server Error
      enqueueSnackbar('Server Error', { variant: 'error' })
      break;
    default:
      break;
  }

  return Promise.reject(error.message);
})

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: any) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: any) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

const Games = {
  list: (search: string) => requests.get<Game[]>(`/game?search=${search}`),
  details: (gameId: string) => requests.get<Game>(`/game/${gameId}`)
}

const Votes = {
  list: (groupGamePlayId: string) => requests.get<Vote[]>(`/vote/gameplay/${groupGamePlayId}`),
  create: (createVote: CreateVote) => requests.post<void>(`/vote`, createVote)
}

const Groups = {
  list: () => requests.get<Group[]>('/group'),
  getByName: (name: string) => requests.get<Group>(`/group/name/${name}`),
  discover: (discoverGroupsRequest: DiscoverGroupsRequest) => requests.get<DiscoverGroupResponse>(`/group/discover?orderBy=${discoverGroupsRequest.orderBy}&take=${discoverGroupsRequest.take}&skip=${discoverGroupsRequest.skip}`),
  join: (groupId: string) => requests.post<void>(`/group/${groupId}/join`, {}),
  leave: (groupId: string) => requests.post<void>(`/group/${groupId}/leave`, {}),
  details: (groupId: string) => requests.get<Group>(`/group/${groupId}`),
  exists: (name: string) => requests.get<boolean>(`/group/exists?name=${name}`),
  create: (group: CreateGroupRequest) => requests.post<void>('/group', group),
  update: (group: UpdateGroupRequest) => requests.put<void>(`/group/${group.groupId}`, group),
  delete: (id: string) => requests.del<void>(`/group/${id}`),
  invite: (groupInviteRequest: GroupInvite) => requests.post<void>(`/group/${groupInviteRequest.groupId}/invite`, groupInviteRequest),
  getInvite: (groupInviteRequest: GroupInvite) => requests.get<GroupInvite>(`/group/${groupInviteRequest.groupId}/invite/${groupInviteRequest.groupInviteId}`),
  acceptInvite: (groupInviteRequest: GroupInvite) => requests.put<void>(`/group/${groupInviteRequest.groupId}/invite/${groupInviteRequest.groupInviteId}`, {}),
  kickPlayer: (kickPlayer: KickPlayer) => requests.put<void>(`/group/${kickPlayer.groupId}/kick/${kickPlayer.playerId}`, {}),
}

const GroupGamePlays = {
  list: () => requests.get<GroupGamePlay[]>('/groupgameplay'),
  listByGroup: (groupId: string) => requests.get<GroupGamePlay[]>(`/groupgameplay?groupId=${groupId}`),
  create: (groupGamePlay: CreateGroupGamePlayRequest) => requests.post<void>(`/groupgameplay/group/${groupGamePlay.groupId}`, groupGamePlay),
  details: (groupGamePlayId: string) => requests.get<GroupGamePlay>(`/groupgameplay/${groupGamePlayId}`),
  startGamePlay: (groupGamePlay: StartGroupGamePlayRequest) => requests.put<void>(`/groupgameplay/${groupGamePlay.groupGamePlayId}/startgameplay`, groupGamePlay),
  update: (groupGamePlay: UpdateGroupGamePlayRequest) => requests.put<void>(`/groupgameplay/${groupGamePlay.groupGamePlayId}`, groupGamePlay),
  delete: (groupGamePlayId: string) => requests.del<void>(`/groupgameplay/${groupGamePlayId}`)
}

const GameBeats = {
  list: () => requests.get<GameBeat[]>('/gamebeat'),
  listByGroupGamePlay: (groupGamePlayId: string) => requests.get<GameBeat[]>(`/gamebeat?groupGamePlayId=${groupGamePlayId}`),
  create: (groupId: string, gameBeat: CreateGameBeatRequest) => requests.post<void>(`/gamebeat?groupId=${groupId}`, gameBeat),
  details: (gameBeatId: string) => requests.get<GameBeat>(`/gamebeat/${gameBeatId}`),
  update: (gameBeat: UpdateGameBeatRequest) => requests.put<void>(`/gamebeat/${gameBeat.gameBeatId}`, gameBeat),
  delete: (gameBeatId: string) => requests.del<void>(`/gamebeat/${gameBeatId}`)
}

const Players = {
  list: () => requests.get<Player[]>('/player'),
  listByGroup: (groupId: string) => requests.get<Player[]>(`/player/group/${groupId}`),
  details: (playerId: string) => requests.get<PlayerDetails>(`/player/${playerId}`)
}

const Account = {
  current: () => requests.get<User>('/account'),
  login: (user: UserSignInFormValues) => requests.post<User>('/account/login', user),
  register: (user: UserSignInFormValues) => requests.post<User>('/account/register', user),
  forgotPassword: (user: UserForgotPasswordFormValues) => requests.post<void>('/account/forgotpassword', user),
  resetPassword: (user: UserResetPasswordFormValues) => requests.post<User>('/account/resetpassword', user),
  unsubscribe: (user: UserForgotPasswordFormValues) => requests.post<void>('/account/unsubscribe', user),
  updateUserName: (updateUserNameFormValues: UpdateUserNameFormValues) => requests.put<User>('/account/updateUserName', updateUserNameFormValues),
  updateEmail: (updateEmailFormValues: UpdateEmailFormValues) => requests.put<User>('/account/updateEmail', updateEmailFormValues),
  sendChangeEmail: (sendChangeEmailValues: SendChangeEmailValues) => requests.post<User>('/account/sendChangeEmail', sendChangeEmailValues),
  confirmEmail: (confirmEmailValues: ConfirmEmailValues) => requests.post<User>('/account/confirmEmail', confirmEmailValues),
  sendConfirmEmail: () => requests.post<User>('/account/sendConfirmEmail', {}),
  updatePassword: (updatePasswordFormValues: UpdatePasswordFormValues) => requests.put<User>('/account/updatePassword', updatePasswordFormValues),
  updateNotifications: (updateNotifictionsValues: UpdateNotificationsFormValues) => requests.put<User>('/account/updateNotifications', updateNotifictionsValues)
}

const GroupRequests = {
  listByGroup: (groupId: string) => requests.get<GroupRequest[]>(`/grouprequest/group/${groupId}`),
  create: (groupId: string) => requests.post<void>(`/grouprequest`, groupId),
  approve: (approveGroupRequest: ApproveGroupRequest) => requests.put<void>(`/grouprequest`, approveGroupRequest),
  delete: (groupRequestId: string) => requests.del<void>(`/grouprequest/${groupRequestId}`),
}

const Notifications = {
  list: () => requests.get<Notification[]>(`/notification`),
  dismiss: (notificationId: string) => requests.put<void>(`/notification/${notificationId}/dismiss`, {}),
  delete: (notificationId: string) => requests.del<void>(`/notification/${notificationId}`)
}

const agent = {
  Groups,
  GroupRequests,
  GroupGamePlays,
  GameBeats,
  Players,
  Account,
  Votes,
  Games,
  Notifications
}

export default agent;
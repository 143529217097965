import agent from "api/agent";
import { Game } from "api/game";
import { makeAutoObservable } from "mobx";

export default class GameStore {
  gameRegistry = new Map<string, Game>();
  externalGameRegistry = new Map<number, Game>();
  loading = false;
  loadingSearch = false;

  constructor() {
    makeAutoObservable(this);
  }

  get gamesByName() {
    return Array.from(this.gameRegistry.values()).sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  get externalGamesByName() {
    return Array.from(this.externalGameRegistry.values()).sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  loadGame = async (gameId: string) => {
    // Check if game has already in game registry
    const existingGame = this.gameRegistry.get(gameId);
    if (existingGame)
      return;

    this.setLoading(true);
    try {
      const game = await agent.Games.details(gameId);
      this.setGame(game);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  searchExternalGames = async (search: string) => {
    this.setLoadingSearch(true);
    try {
      const games = await agent.Games.list(search);
      this.setExternalGames(games);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingSearch(false);
    }
  }

  clearExternalGames = () => {
    this.externalGameRegistry.clear();
  }

  private setLoading = (state: boolean) => {
    this.loading = state;
  }

  private setLoadingSearch = (state: boolean) => {
    this.loadingSearch = state;
  }

  private setExternalGames = (games: Game[]) => {
    this.externalGameRegistry.clear();

    games.forEach(game => {
      this.setExternalGame(game);
    });
  }

  private setExternalGame = (game: Game) => {
    this.externalGameRegistry.set(game.externalId, game);
  }

  private setGames = (games: Game[]) => {
    games.forEach(game => {
      this.setGame(game);
    });
  }

  private setGame = (game: Game) => {
    this.gameRegistry.set(game.gameId, game);
  }
}
import agent from "api/agent";
import { Notification } from "api/notification";
import { makeAutoObservable } from "mobx";

export default class NotificationStore {
  notificationRegistry = new Map<string, Notification>();
  loading = false;
  loadingCUDNotificationId : string | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get notificationsOrderedByDateCreated() {
    return Array.from(this.notificationRegistry.values()).sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
  }

  loadNotifications = async () => {
    this.setLoading(true);
    try {
      const notifications = await agent.Notifications.list();
      this.setNotifications(notifications);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  dismissNotification = async (notificationId: string) => {
    this.setLoadingCUDNotificationId(notificationId);
    try {
      await agent.Notifications.dismiss(notificationId);
      this.deleteNotification(notificationId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUDNotificationId(undefined);
    }
  }

  private setNotifications = (notifications: Notification[]) => {
    notifications.forEach(notification => {
      this.setNotification(notification);
    });
  }

  private setNotification = (notification: Notification) => {
    this.notificationRegistry.set(notification.notificationId, notification);
  }

  private deleteNotification = (notificationId: string) => {
    this.notificationRegistry.delete(notificationId);
  }

  private setLoading = (state: boolean) => {
    this.loading = state;
  }

  private setLoadingCUDNotificationId = (notificationId: string | undefined) => {
    this.loadingCUDNotificationId = notificationId;
  }
}
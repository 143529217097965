import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import DialogStore from "./dialogStore";
import DiscoverStore from "./discoverStore";
import GameBeatStore from "./gameBeatStore";
import GameStore from "./gameStore";
import GroupGamePlayStore from "./groupGamePlayStore";
import GroupRequestStore from "./groupRequestStore";
import GroupStore from "./groupStore";
import NotificationStore from "./notificationStore";
import PlayerDrawerStore from "./playerDrawerStore";
import PlayerStore from "./playerStore";
import UserStore from "./userStore";
import VoteStore from "./voteStore";

interface Store {
  playerStore: PlayerStore;
  groupRequestStore: GroupRequestStore;
  groupStore: GroupStore;
  groupGamePlayStore: GroupGamePlayStore;
  gameBeatStore: GameBeatStore;
  userStore: UserStore;
  commonStore: CommonStore;
  dialogStore: DialogStore;
  playerDrawerStore: PlayerDrawerStore;
  discoverStore: DiscoverStore;
  voteStore: VoteStore;
  gameStore: GameStore;
  notificationStore: NotificationStore;
}

const playerStore = new PlayerStore();
const groupRequestStore = new GroupRequestStore(playerStore);
const groupStore = new GroupStore(playerStore, groupRequestStore);
const gameStore = new GameStore();
const groupGamePlayStore = new GroupGamePlayStore(groupStore, gameStore);
const gameBeatStore = new GameBeatStore();
const userStore = new UserStore();
const commonStore = new CommonStore();
const dialogStore = new DialogStore();
const playerDrawerStore = new PlayerDrawerStore();
const discoverStore = new DiscoverStore(groupStore);
const voteStore = new VoteStore(gameStore);
const notificationStore = new NotificationStore();

export const store: Store = {
  playerStore: playerStore,
  groupRequestStore: groupRequestStore,
  groupStore: groupStore,
  groupGamePlayStore: groupGamePlayStore,
  gameBeatStore: gameBeatStore,
  userStore: userStore,
  commonStore: commonStore,
  dialogStore: dialogStore,
  playerDrawerStore: playerDrawerStore,
  discoverStore: discoverStore,
  voteStore: voteStore,
  gameStore: gameStore,
  notificationStore: notificationStore
}

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
import { DialogProps } from "@mui/material/Dialog";
import { makeAutoObservable } from "mobx"

interface Dialog {
  open: boolean;
  title: string | null;
  body: JSX.Element | null;
  maxWidth: DialogProps['maxWidth'];
}

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export default class DialogStore {
  dialog: Dialog = {
    open: false,
    title: null,
    body: null,
    maxWidth: undefined
  }

  constructor() {
    makeAutoObservable(this);
  }

  openDialog = (title: string, content: JSX.Element, maxWidth: DialogProps['maxWidth'] = 'sm') => {
    this.dialog.open = true;
    this.dialog.title = title;
    this.dialog.body = content;
    this.dialog.maxWidth = maxWidth
  }

  closeModal = async () => {
    this.dialog.open = false;

    // Wait for dialog to fall off screen
    await delay(200);
    
    this.dialog.title = null;
    this.dialog.body = null;
    this.dialog.maxWidth = undefined;
  }
}
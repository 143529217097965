import agent from "api/agent";
import { CreateGroupGamePlayRequest } from "api/group";
import { GroupGamePlay, StartGroupGamePlayRequest, UpdateGroupGamePlayRequest } from "api/groupGamePlay";
import { makeAutoObservable } from "mobx";
import GameStore from "./gameStore";
import GroupStore from "./groupStore";

/**
 * Represents a store for managing group game play data.
 */
export default class GroupGamePlayStore {
  groupGamePlayRegistry = new Map<string, GroupGamePlay>();
  groupStore: GroupStore;
  gameStore: GameStore;
  selectedGroupGamePlay: GroupGamePlay | undefined = undefined;
  loading = false;
  loadingCUD = false;

  constructor(groupStore: GroupStore, gameStore: GameStore) {
    makeAutoObservable(this);
    this.groupStore = groupStore;
    this.gameStore = gameStore;
  }

  /**
   * Loads the group game plays for a group.
   * 
   * @param groupId - The ID of the group to load the game plays for.
   * @throws {Error} - If there are any errors during the loading process.
   */
  loadGroupGamePlays = async (groupId: string) => {
    this.setLoading(true);
    try {
      const groupGamePlays = await agent.GroupGamePlays.listByGroup(groupId);
      this.setGroupGamePlays(groupGamePlays);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Creates a new group game play.
   * 
   * @param createGroupGamePlayRequest - The request object containing the new group game play data.
   * @throws {Error} - If there are any errors during the creation process.
   */
  createGroupGamePlay = async (createGroupGamePlayRequest: CreateGroupGamePlayRequest) => {
    this.setLoadingCUD(true);
    try {
      await agent.GroupGamePlays.create(createGroupGamePlayRequest);
      this.groupStore.loadGroupWithId(createGroupGamePlayRequest.groupId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  /**
   * Updates the group game play.
   * 
   * @param updateGroupGamePlayRequest - The request object containing the updated group game play data.
   * @throws {Error} - If there are any errors during the update process.
   */
  updateGroupGamePlay = async (updateGroupGamePlayRequest: UpdateGroupGamePlayRequest) => {
    this.setLoadingCUD(true);
    try {
      await agent.GroupGamePlays.update(updateGroupGamePlayRequest);
      this.groupStore.loadGroupWithId(updateGroupGamePlayRequest.groupId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  /**
   * Starts the group game play.
   * 
   * @param startGroupGamePlayRequest - The request object containing the start group game play data.
   * @throws {Error} - If there are any errors during the update process.
   */
  startGroupGamePlay = async (startGroupGamePlayRequest: StartGroupGamePlayRequest) => {
    this.setLoadingCUD(true);
    try {
      await agent.GroupGamePlays.startGamePlay(startGroupGamePlayRequest);
      this.groupStore.loadGroupWithId(startGroupGamePlayRequest.groupId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }


  /**
   * Deletes a group game play by its ID.
   * @param {string} groupGamePlayId - The ID of the group game play to delete.
   * @throws {any} - Any errors that occur during the deletion process.
   */
  deleteGamePlay = async (groupGamePlayId: string) => {
    this.setLoadingCUD(true);
    try {
      await agent.GroupGamePlays.delete(groupGamePlayId);
      this.groupGamePlayRegistry.delete(groupGamePlayId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  get groupGamePlaysByStartDate() {
    return Array.from(this.groupGamePlayRegistry.values());
  }

  private setGroupGamePlays = (groupGamePlays: GroupGamePlay[]) => {
    groupGamePlays.forEach(groupGamePlay => {
      this.setGroupGamePlay(groupGamePlay);
    })
  }

  private setGroupGamePlay = (groupGamePlay: GroupGamePlay) => {
    this.groupGamePlayRegistry.set(groupGamePlay.groupGamePlayId, groupGamePlay);
  }

  private setLoading = (state: boolean) => {
    this.loading = state;
  }

  private setLoadingCUD = (state: boolean) => {
    this.loadingCUD = state;
  }
}
import agent from "api/agent";
import { Group, GroupCreate, GroupInvite, GroupUpdate, KickPlayer } from "api/group";
import { makeAutoObservable } from "mobx";
import GroupRequestStore from "./groupRequestStore";
import PlayerStore from "./playerStore";

export default class GroupStore {
  groupRegistry = new Map<string, Group>();
  playerStore: PlayerStore;
  groupRequestStore: GroupRequestStore;
  selectedGroup: Group = {} as Group;
  groupInvite: GroupInvite | undefined = undefined;
  loadingCUD = false;
  loadingGroups = true;
  loadingGroup = true;
  groupExists = true;
  loadingInvite = false;
  loadingAcceptInvite = false;
  loadingKickPlayer = false;

  constructor(playerStore: PlayerStore, groupRequestStore: GroupRequestStore) {
    makeAutoObservable(this);
    this.playerStore = playerStore;
    this.groupRequestStore = groupRequestStore;
  }

  // get groupsByDate() {
  //   return Array.from(this.groupRegistry.values()).sort((a, b) => 
  //     Date.parse(a.createdAt) - Date.parse(b.createdAt));
  // }

  get groupsByName() {
    return Array.from(this.groupRegistry.values()).sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  loadGroups = async () => {
    try {
      const groups = await agent.Groups.list();
      this.setGroups(groups);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingGroups(false);
    }
  }

  loadGroup = async (name: string) => {
    this.setLoadingGroup(true);
    this.playerStore.setLoading(true);
    this.groupRequestStore.setLoading(true);
    try {
      // Only one group should return
      const group = await agent.Groups.getByName(name);
      await this.playerStore.loadPlayers(group.groupId);
      // Remove because when a user isn't a member of the group, then don't show the group in the users list of groups
      // this.setGroup(group);
      this.selectGroup(group);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingGroup(false);
    }
  }

  loadGroupWithId = async (groupId: string) => {
    this.setLoadingGroup(true);
    this.playerStore.setLoading(true);
    try {
      // Only one group should return
      const group = await agent.Groups.details(groupId);
      await this.playerStore.loadPlayers(group.groupId);
      this.setGroup(group);
      this.selectGroup(group);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingGroup(false);
    }
  }

  doesGroupExists = async (name: string) => {
    this.setLoadingCUD(true);
    let result = await agent.Groups
      .exists(name)
      .catch(err => {
        return false;
      });

    this.setLoadingCUD(false);
    return result;
  }

  createGroup = async (group: GroupCreate) => {
    this.setLoadingCUD(true);
    try {
      await agent.Groups.create(group);
      await this.loadGroups();
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  updateGroup = async (group: GroupUpdate) => {
    this.setLoadingCUD(true);
    try {
      await agent.Groups.update(group);
      const updatedGroup = await agent.Groups.details(group.groupId);
      this.setGroup(updatedGroup);
      this.selectGroup(updatedGroup);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  deleteGroup = async (groupId: string) => {
    this.setLoadingCUD(true);
    try {
      await agent.Groups.delete(groupId);
      this.groupRegistry.delete(groupId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  leaveGroup = async (groupId: string) => {
    this.setLoadingCUD(true);
    try {
      await agent.Groups.leave(groupId);
      this.groupRegistry.delete(groupId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  invite = async (groupInvite: GroupInvite) => {
    this.setLoadingInvite(true);
    try {
      await agent.Groups.invite(groupInvite);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingInvite(false);
    }
  }

  getInvite = async (groupInvite: GroupInvite) => {
    this.setLoadingInvite(true);
    try {
      this.groupInvite = await agent.Groups.getInvite(groupInvite);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingInvite(false);
    }
  }

  acceptInvite = async (groupInviteRequest: GroupInvite) => {
    this.setLoadingAcceptInvite(true);
    try {
      await agent.Groups.acceptInvite(groupInviteRequest);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingAcceptInvite(false);
    }
  }

  kickPlayer = async (kickPlayer: KickPlayer) => {
    this.setLoadingKickPlayer(true);
    try {
      await agent.Groups.kickPlayer(kickPlayer);
      await this.playerStore.loadPlayers(kickPlayer.groupId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingKickPlayer(false);
    }
  }

  private setGroups = (groups: Group[]) => {
    groups.forEach(group => {
      this.setGroup(group);
    });
  }

  private setGroup = (group: Group) => {
    this.groupRegistry.set(group.groupId, group);
  }

  private setLoadingCUD = (state: boolean) => {
    this.loadingCUD = state;
  }

  private setLoadingGroups = (state: boolean) => {
    this.loadingGroups = state;
  }

  private setLoadingGroup = (state: boolean) => {
    this.loadingGroup = state;
  }

  private selectGroup = (group: Group) => {
    this.selectedGroup = group;
  }

  private setLoadingInvite = (state: boolean) => {
    this.loadingInvite = state;
  }

  private setLoadingAcceptInvite = (state: boolean) => {
    this.loadingAcceptInvite = state;
  }

  private setLoadingKickPlayer = (state: boolean) => {
    this.loadingKickPlayer = state;
  }
}
import agent from "api/agent";
import { ApproveGroupRequest, DeleteGroupRequest, GroupRequest } from "api/groupRequest";
import { makeAutoObservable } from "mobx";
import PlayerStore from "./playerStore";

export default class GroupRequestStore {
  groupRequestRegistry = new Map<string, GroupRequest>();
  playerStore: PlayerStore;
  loading = false;
  loadingCUD = false;

  constructor(playerStore: PlayerStore) {
    makeAutoObservable(this);
    this.playerStore = playerStore;
  }

  get groupRequestsByCreatedDate() {
    return Array.from(this.groupRequestRegistry.values()).sort((a, b) => (a.dateCreated < b.dateCreated ? -1 : 1));
  }

  loadGroupRequest = async (groupId: string) => {
    this.setLoading(true);
    try {
      const groupRequests = await agent.GroupRequests.listByGroup(groupId);
      this.setGroupRequests(groupRequests);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  createGroupRequest = async (groupId: string) => {
    this.setLoadingCUD(true);
    try {
      await agent.GroupRequests.create(groupId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  approveGroupRequest = async (approveGroupRequest: ApproveGroupRequest) => {
    this.setLoadingCUD(true);
    try {
      await agent.GroupRequests.approve(approveGroupRequest);
      this.playerStore.loadPlayers(approveGroupRequest.groupId);
      this.loadGroupRequest(approveGroupRequest.groupId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  deleteGroupRequest = async (deleteGroupRequest: DeleteGroupRequest) => {
    this.setLoadingCUD(true);
    try {
      await agent.GroupRequests.delete(deleteGroupRequest.groupRequestId);
      this.playerStore.loadPlayers(deleteGroupRequest.groupId);
      this.loadGroupRequest(deleteGroupRequest.groupId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoadingCUD(false);
    }
  }

  private setGroupRequests = (groupRequests: GroupRequest[]) => {
    this.groupRequestRegistry.clear();
    groupRequests.forEach(groupRequest => {
      this.setGroupRequest(groupRequest);
    })
  }

  private setGroupRequest = (groupRequest: GroupRequest) => {
    this.groupRequestRegistry.set(groupRequest.playerId, groupRequest);
  }

  public setLoading = (state: boolean) => {
    this.loading = state;
  }

  private setLoadingCUD = (state: boolean) => {
    this.loadingCUD = state;
  }
}
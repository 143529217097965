import agent from "api/agent";
import { Player, PlayerDetails } from "api/player";
import { makeAutoObservable } from "mobx";

export default class PlayerStore {
  playerRegistry = new Map<string, Player>();
  selectedPlayer: PlayerDetails | undefined = undefined;
  loading = false;
  loadingInvite = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadPlayers = async (groupId: string) => {
    this.setLoading(true);
    try {
      const players = await agent.Players.listByGroup(groupId);
      this.setPlayers(players);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  loadPlayer = async (playerId: string) => {
    this.setLoading(true);
    try {
      const player = await agent.Players.details(playerId);
      this.setSelectedPlayer(player);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  } 

  get playersByName() {
    return Array.from(this.playerRegistry.values()).sort((a, b) => (a.userName < b.userName ? -1 : 1));
  }

  private setPlayers = (players: Player[]) => {
    this.playerRegistry.clear();
    players.forEach(player => {
      this.playerRegistry.set(player.playerId, player);
    })
  }

  private setSelectedPlayer = (player: PlayerDetails) => {
    this.selectedPlayer = player;
  }

  public setLoading = (state: boolean) => {
    this.loading = state;
  }
}
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '9cce2985-bc75-43e1-8e67-83a2a0a48981',
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
});

export default appInsights;
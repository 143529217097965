import agent from "api/agent";
import { CreateVote, Vote } from "api/vote";
import * as _ from "lodash";
import { makeAutoObservable } from "mobx";
import GameStore from "./gameStore";

export default class VoteStore {
  gameStore = new GameStore();
  voteRegistry = new Map<string, Vote>();
  loading = false;

  constructor(gameStore: GameStore) {
    makeAutoObservable(this);
    this.gameStore = gameStore;
  }

  get votesGroupedByGameId() {
    return _.groupBy(Array.from(this.voteRegistry.values()), (vote: Vote) => vote.gameId)
  }

  clearVotes = () => {
    this.voteRegistry.clear();
  }

  loadVotes = async (groupGamePlayId: string) => {
    this.setLoading(true);
    try {
      const votes = await agent.Votes.list(groupGamePlayId);
      this.setVotes(votes);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  createVote = async (createVote: CreateVote) => {
    this.setLoading(true);
    try {
      await agent.Votes.create(createVote);
      this.loadVotes(createVote.groupGamePlayId);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  private setLoading = (state: boolean) => {
    this.loading = state;
  }

  private setVotes = (votes: Vote[]) => {
    votes.forEach(vote => {
      this.gameStore.loadGame(vote.gameId);
      this.setVote(vote);
    });
  }

  private setVote = (vote: Vote) => {
    this.voteRegistry.set(vote.voteId, vote);
  }
}
import agent from "api/agent";
import {
  ConfirmEmailValues,
  SendChangeEmailValues,
  UpdateEmailFormValues,
  UpdateNotificationsFormValues,
  UpdatePasswordFormValues,
  UpdateUserNameFormValues,
  User,
  UserForgotPasswordFormValues,
  UserResetPasswordFormValues,
  UserSignInFormValues,
  UserSignUpFormValues
} from "api/user";
import { makeAutoObservable } from "mobx";
import { store } from "./store";

export default class UserStore {
  user: User | null = null;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get currentUser() {
    return this.user;
  }

  get isLoggedIn() {
    return !!this.user;
  }

  setLoading = (state: boolean) => {
    this.loading = state;
  }

  setUser = (user: User) => {
    this.user = user;
  }

  login = async (creds: UserSignInFormValues) => {
    this.setLoading(true);
    try {
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  register = async (creds: UserSignUpFormValues) => {
    this.setLoading(true);
    try {
      const user = await agent.Account.register(creds);
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  forgotPassword = async (creds: UserForgotPasswordFormValues) => {
    this.setLoading(true);
    try {
      await agent.Account.forgotPassword(creds);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  resetPassword = async (creds: UserResetPasswordFormValues) => {
    this.setLoading(true);
    try {
      const user = await agent.Account.resetPassword(creds);
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  unsubscribe = async (creds: UserForgotPasswordFormValues) => {
    this.setLoading(true);
    try {
      await agent.Account.unsubscribe(creds);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  logout = () => {
    store.commonStore.setToken(null);
    this.user = null;
  }

  getUser = async () => {
    this.setLoading(true);
    try {
      const user = await agent.Account.current();
      this.setUser(user);
    } catch (errors) {
      store.commonStore.setToken(null);
    } finally {
      this.setLoading(false);
    }
  }

  updateUserName = async (userNameFormValues: UpdateUserNameFormValues) => {
    this.setLoading(true);
    try {
      const user = await agent.Account.updateUserName(userNameFormValues);
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  updateEmail = async (emailFormValues: UpdateEmailFormValues) => {
    this.setLoading(true);
    try {
      const user = await agent.Account.updateEmail(emailFormValues);
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  sendChangeEmail = async (sendChangeEmailValues: SendChangeEmailValues) => {
    this.setLoading(true);
    try {
      const user = await agent.Account.sendChangeEmail(sendChangeEmailValues);
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  sendConfirmEmail = async () => {
    this.setLoading(true);
    try {
      const user = await agent.Account.sendConfirmEmail();
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  confirmEmail = async (confirmEmailValues: ConfirmEmailValues) => {
    this.setLoading(true);
    try {
      const user = await agent.Account.confirmEmail(confirmEmailValues);
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  updatePassword = async (passwordFormValues: UpdatePasswordFormValues) => {
    this.setLoading(true);
    try {
      const user = await agent.Account.updatePassword(passwordFormValues);
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }

  updateNotifications = async (updateNotificationsFormValues: UpdateNotificationsFormValues) => {
    this.setLoading(true);
    try {
      const user = await agent.Account.updateNotifications(updateNotificationsFormValues);
      store.commonStore.setToken(user.token);
      this.setUser(user);
    } catch (errors) {
      throw errors;
    } finally {
      this.setLoading(false);
    }
  }
}